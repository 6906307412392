import { Entry } from "contentful";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";

import Button from "~/components/common/button";
import useScript from "~/hooks/use-script";
import Editorials from "~/types/editorials";
import { assertEditorialType } from "~/utils/editorial-utils";

type Props = {
  entry: Entry<unknown>;
};

export default function SmartTribuneFaq(props: Props) {
  assertEditorialType<Editorials.RevieveModalTrigger>(props.entry, "smartTribuneFaq");

  const router = useRouter();
  const [path, setPath] = useState(router.asPath);

  const lang = useMemo(() => router.locale?.split("-")[0] || "en", [router.locale]);
  const faqScripts: Record<string, string> = {
    _default: process.env.NEXT_PUBLIC_SMART_TRIBUNE_FAQ_JS_DEFAULT_URL!,
    ar: process.env.NEXT_PUBLIC_SMART_TRIBUNE_FAQ_JS_ARABIC_URL!,
  };

  // WORKAROUND check if the smart tribune query has changed, then refresh the page to avoid visual errors
  useEffect(() => {
    setPath(router.asPath);
    if (router.asPath !== path && path !== undefined) {
      router.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, router.asPath]);

  const faqLoaded = useScript(faqScripts[lang] || faqScripts._default, true);

  const guideLoaded = useScript(process.env.NEXT_PUBLIC_SMART_TRIBUNE_GUIDE_JS_URL, true);

  useScript(`https://static.kikocosmetics.com/public/smart-tribune/${router.locale}/faq-init.js`, true);

  return (
    <>
      <div id="st-faq" style={{ minHeight: "100px" }}>
        {(!faqLoaded || !guideLoaded) && <Button loading={true} style={{ cursor: "default" }}></Button>}
      </div>
      <div id="st-guide-standalone" />
    </>
  );
}
